import { useState, useEffect } from "react";
import { Icon } from '@iconify/react';
import { useDataContext } from "../../middleware/dataContext";

export default function AdminNavbar({ activeTab, pageTitle }) {
    const [currentTab, setCurrentTab] = useState(activeTab);
    const{user,globalNotifications} = useDataContext();

    useEffect(() => {
        setCurrentTab(activeTab);
    }, [activeTab]);

    const sentenceCase = (str) => {
        if (!str) return '';
        return str.replace(/\w\S*/g, (txt) =>
            txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
        );
    };

    const renderPathHierarchy = () => {
        const path = window.location.pathname;
        const segments = path.split('/').filter(segment => segment !== '');
        
        if (segments.length === 0) {
            return <span className="font-semibold text-secondary-base">Home</span>;
        }

        return segments.map((segment, index) => {
            const isLast = index === segments.length - 1;
            const isCurrentTab = index === segments.length - 1 && currentTab;

            return (
                <span key={index}>
                    <span className={`cursor-pointer ${isLast && !isCurrentTab ? 'font-semibold text-secondary-base' : 'text-gray-600'}`}>
                        {sentenceCase(segment)}
                    </span>
                    {isCurrentTab && (
                        <>
                            {" > "}
                            <span className="font-semibold text-secondary-base">
                                {sentenceCase(currentTab)}
                            </span>
                        </>
                    )}
                    {!isLast && !isCurrentTab && " > "}
                </span>
            );
        });
    };

    return (
        <nav className="w-full h-[4.5em] bg-white shadow-md py-1 px-6 flex justify-between items-center">
            <div className="text-gray-600 text-xs">
                {renderPathHierarchy()}
                <h2 className="text-2xl font-semibold mt-1 text-gray-dark">{pageTitle}</h2>
            </div>
            <div className="flex items-center space-x-4">
                <div className="p-4 border-r">
                    <div className="bg-primary-base rounded-[5em] flex items-center justify-center p-1">
                        <button className="text-gray-600 hover:text-gray-900">
                            <Icon icon="mdi:bell-outline" className="text-gray-white" width={'20'} height={'20'} />
                        </button>
                        <span className="h-4 w-4 text-white rounded-full flex items-center justify-center text-xs">{globalNotifications?.length}</span>
                    </div>
                </div>
                <div className="flex items-center space-x-2">
                    <img src="/assets/profile.png" alt="Profile" className="w-10 h-10 rounded-full" />
                    <span className="text-gray-700 text-xs">
                        Welcome,<br/>
                        <span className="text-[1.2em] text-gray-dark font-semibold">{user?.name}</span>
                    </span>
                </div>
                <div>
                    <Icon icon="icon-park-outline:down" width="20" height="20"/>
                </div>
            </div>
        </nav>
    );
}