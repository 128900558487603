// AdminLayout.jsx
import { Fragment, useEffect } from "react";
import AdminNavbar from "./navbar/AdminNavbar";
import AdminSideBar from "./navbar/AdminSideBar";
import { useTabContext } from "../middleware/tabContext";
import { useLocation } from "react-router-dom";

export default function AdminLayout({ children }) {
    const location = useLocation();
    const {
        activeTab,
        activeSidebar,
        pageTitle,
        setActiveSidebar,
        setPageTitle,
        changeTab
    } = useTabContext();

    useEffect(() => {
        const path = location.pathname;
        const segments = path.split('/').filter(segment => segment !== '');

        const capitalize = (str) => {
            return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        };

        if (segments.length > 0) {
            const sidebarSegment = segments[0] === 'dashboard' ? 
                (segments[1] ? capitalize(segments[1]) : 'Dashboard') : 
                capitalize(segments[0]);

            setActiveSidebar(sidebarSegment);

            switch (sidebarSegment.toLowerCase()) {
                case 'dashboard':
                    setPageTitle('At a Glance');
                    changeTab('Summary');
                    break;
                case 'services':
                    setPageTitle('Service Management');
                    changeTab('Service');
                    break;
                case 'customers':
                    setPageTitle('Customer Management');
                    changeTab('All Customers');
                    break;
                case 'settings':
                    setPageTitle('System Settings');
                    changeTab('General');
                    break;
                case 'employee':
                    setPageTitle('Employee Management');
                    changeTab('Overview');
                    break;
                case 'chats':
                    setPageTitle('Chat Management');
                    changeTab('All Chats');
                    break;
                case 'application form':
                    setPageTitle('Application Management');
                    changeTab('Forms');
                    break;
                default:
                    setPageTitle(capitalize(sidebarSegment));
                    changeTab('Overview');
            }
        } else {
            setActiveSidebar('Dashboard');
            setPageTitle('At a Glance');
            changeTab('Summary');
        }
    }, [location.pathname]);

    return (
        <Fragment>
            <div className="flex h-screen">
                <AdminSideBar activeTab={activeSidebar} />
                <div className="flex-1 flex flex-col">
                    <AdminNavbar pageTitle={pageTitle} activeTab={activeTab} />
                    <main className="p-6 bg-gray-100 flex-1 overflow-auto">
                        {children}
                    </main>
                </div>
            </div>
        </Fragment>
    );
}
