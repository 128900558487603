import React, { createContext, useContext, useState } from "react";

const TabContext = createContext();

export const useTabContext = () => {
    return useContext(TabContext);
};

export const TabProvider = ({ children }) => {
    const [activeTab, setActiveTab] = useState('Summary');
    const [activeSidebar,setActiveSidebar] = useState('Dashboard');
    const [pageTitle,setPageTitle] = useState('At a Glance');

    const changeTab = (newTab) => {
        setActiveTab(newTab);
    };

    return (
        <TabContext.Provider value={{ activeTab, changeTab,activeSidebar,setActiveSidebar,pageTitle,setPageTitle }}>
            {children}
        </TabContext.Provider>
    );
};
