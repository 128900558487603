import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminLayout from './components/AdminLayout';
import { AuthorizeUser } from './middleware/auth';
import PageNotFound from './containers/pageNotFound';

// navItems: [
//     {
//         title: 'Dashboard',
//         icon: DashboardIcon,
//         component: Home,
//     },
//     {
//         title: 'Services',
//         icon: ServicesIcon,
//         component: ServicesComponent,
//     },
//     {
//         title: 'Application Form',
//         icon: ApplicationForm,
//         component: ApplicationFormComponent,
//     },
//     {
//         title: 'Customers',
//         icon: Customers,
//         component: CustomersComponent,  
//     },
//     {
//         title: 'Employee',
//         icon: Employee,
//         component: EmployeeComponent,  
//     },
//     {
//         title: 'Chats',
//         icon: Chats,
//         component: ChatsComponent,  
//     },
//     {
//         title: 'Leave Application',
//         icon: LeaveApplication,
//         component: LeaveApplicationComponent,  
//     },
//     {
//         title: 'Payslips',
//         icon: Payslips,
//         component: PayslipsComponent,  
//     },
// ]

const Dashboard = lazy(() => import('./containers/dashboard'));
const AddDriver = lazy(() => import('./containers/add-driver'));
const Services = lazy(() => import('./containers/DashboardTabs/services'));
const Customers = lazy(() => import('./containers/DashboardTabs/customers'));
const Employee = lazy(() => import('./containers/DashboardTabs/employee'));
const Customer = lazy(() => import('./containers/customerView'));
const Driver = lazy(() => import('./containers/driverView'));
const Booking = lazy(() => import('./containers/bookingView'));

const AppRoutes = () => {

    return (
        <Router>
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route
                        path='/dashboard'
                        element={
                            <AuthorizeUser>
                            <AdminLayout >
                                <Dashboard />
                            </AdminLayout>
                            </AuthorizeUser>
                        }
                    />
                    <Route
                        path='/add-driver'
                        element={
                                <AddDriver />
                        }
                    />
                    <Route
                        path='/dashboard/services'
                        element={
                            <AdminLayout>
                                <Services />
                            </AdminLayout>
                        }
                    />
                    <Route
                        path='/dashboard/customers'
                        element={
                            <AdminLayout>
                                <Customers />
                            </AdminLayout>
                        }
                    />
                    <Route
                        path='/dashboard/employee'
                        element={
                            <AdminLayout>
                                <Employee />
                            </AdminLayout>
                        }
                    />
                    <Route 
                        path='/dashboard/customers/customer'
                        element={
                            <AdminLayout>
                                <Customer />
                            </AdminLayout>
                        }
                    />
                    <Route 
                        path='/dashboard/services/booking'
                        element={
                            <AdminLayout>
                                <Booking />
                            </AdminLayout>
                        }
                    />
                    <Route 
                        path='/dashboard/employee/driver'
                        element={
                            <AdminLayout>
                                <Driver />
                            </AdminLayout>
                        }
                    />
                    <Route 
                        path='/forbidden'
                        element={
                            <div>
                                Server is Off Please try later
                            </div>
                        }
                    />
                    <Route
                        path='*'
                        element={
                                <PageNotFound />
                        } 
                    />
                </Routes>
            </Suspense>
        </Router>
    );
}

export default AppRoutes;