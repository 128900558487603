import AppRoutes from "./router";
import './app.scss';
import { DataProvider } from "./middleware/dataContext";
import { TabProvider } from "./middleware/tabContext";
import { AuthProvider } from "./middleware/store";

function App() {
  return (
    <AuthProvider>
    <DataProvider>
      <TabProvider>
      <AppRoutes/>
      </TabProvider>
    </DataProvider>
    </AuthProvider>
)
}

export default App;
