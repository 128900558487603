import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ServicesIcon from '../icons/services.svg';
import DashboardIcon from '../icons/dashboard.svg';
import ApplicationForm from '../icons/applicationForm.svg';
import Employee from '../icons/employee.svg';
import Customers from '../icons/customers.svg';
import LeaveApplication from '../icons/leaveapplication.svg';
import Payslips from '../icons/payslips.svg';
import Chats from '../icons/chats.svg';

export default function AdminSideBar({ activeTab }) {
    const navigate = useNavigate();

    const navItems = [
        {
            title: 'Dashboard',
            icon: DashboardIcon,
        },
        {
            title: 'Services',
            icon: ServicesIcon,
        },
        {
            title: 'Application Form',
            icon: ApplicationForm,
        },
        {
            title: 'Customers',
            icon: Customers,
        },
        {
            title: 'Employee',
            icon: Employee,
        },
        {
            title: 'Chats',
            icon: Chats,
        },
        {
            title: 'Leave Application',
            icon: LeaveApplication,
        },
        {
            title: 'Payslips',
            icon: Payslips,
        },
    ];

    const [state, setState] = useState({
        activeTab,
        navItems: [...navItems]
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            activeTab
        }));
    }, [activeTab]);

    function handleNavClick(title) {
        const path = title.toLowerCase() === 'dashboard' 
            ? '/dashboard'
            : `/dashboard/${title.toLowerCase().replace(' ', '-')}`;
        navigate(path);
    }

    return (
        <aside className="w-[5em] bg-gray-white min-h-screen flex flex-col items-center pt-[6em]">
            <div className="fixed top-0 left-0 flex items-center justify-center w-[5.5em] h-[5em] rounded-e-lg bg-white shadow-lg z-20">
                <img src="/ODD Logo 2.svg" alt="Logo" className="w-[5em] h-[5em]" />
            </div>
            <nav className="flex flex-col text-xs items-center space-y-2">
                {state.navItems.map((item, index) => (
                    <span 
                        onClick={() => handleNavClick(item.title)} 
                        key={index} 
                        className="cursor-pointer text-gray-tertiary flex flex-col items-center w-[100%] text-center"
                    >
                        <div className={`p-2 w-[45%] ${item.title === state.activeTab ? 'bg-primary-base rounded-lg text-gray-white' : ''}`}>
                            <img 
                                src={item.icon} 
                                alt="icon" 
                                className={`${item.title === 'Dashboard' ? 'drop-shadow' : ''} ${item.title === state.activeTab ? 'text-gray-dark' : ''}`} 
                                width={'100%'} 
                            />
                        </div>
                        <span className={`${state.activeTab === item.title ? 'text-primary-base font-semibold' : ''}`}>
                            {item.title}
                        </span>
                    </span>
                ))}
            </nav>
        </aside>
    );
}