import { Navigate } from "react-router-dom";
import { useAuth } from "./store";

export const AuthorizeUser = ({ children, subdomain }) => {

    const { state } = useAuth()



    const user = state?.user;
    const token = state?.isAuthenticated;
    if (!token) {
        return <Navigate to={"/login"} replace={true}></Navigate>
    }
    // if (!user) {
    //     return <Navigate to={"/login"} replace={true}></Navigate>
    // }

    return children;

}