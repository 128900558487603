import React, { createContext, useState, useContext } from "react";
import PropTypes from "prop-types";

const DataContext = createContext();

export const useDataContext = () => useContext(DataContext);

export const DataProvider = ({ children }) => {


    const [user, setUser] = useState(null);
    const [theme, setTheme] = useState("light");
    const [globalNotifications, setGlobalNotifications] = useState([]);
    const [customers,setCustomers] = useState([]);
    const [drivers,setDrivers] = useState([]);
    const [trips,setTrips] = useState([]);
    const [bookings,setBookings] = useState([]);

    const updateUser = (userData) => setUser(userData);
    const toggleTheme = () => setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
    const addNotification = (notification) =>
        setGlobalNotifications((prevNotifications) => [...prevNotifications, notification]);
    const clearNotifications = () => setGlobalNotifications([]);

    const contextValue = {
        user,
        setUser: updateUser,
        theme,
        setTheme: toggleTheme,
        globalNotifications,
        addNotification,
        clearNotifications,
        customers,
        setCustomers,
        drivers,
        setDrivers,
        trips,
        setTrips,
        bookings,
        setBookings
    };

    return <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>;
};

DataProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
